

.ant-layout-header {
  transition: width 0.2s;
  z-index: 9;
  position: relative;
  height: 64px;
  background: #fff !important;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .right-menu {
    float: right;
    .dropdown-wrap {
      cursor: pointer;
      display: inline-block;
      .ant-dropdown-menu-item {
        padding: 6px 20px 8px 15px;
        i {
          padding-right: 15px;
        }
      }
      .anticon-caret-down {
        vertical-align: bottom;
        padding: 0 0 12px 6px;
      }
    }
  }
}
.fix-header {
  position: fixed;
  top: 0;
  right: 0;
}
